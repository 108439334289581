// 3rd party imports
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { usePlatformContext } from '@bernie/platform-context';
// ui
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
import { SigninAndJoinNav } from 'shared/layout/signin-and-join-nav';
import { EventType, headerLogoSelectedEvent, makeLocaleParam, useCommonClickStreamEvent } from 'shared/utils';
import { UitkTertiaryButton } from 'uitk-react-button';
var navItems = [{
  label: 'Benefits',
  anchor: '#benefits'
}, {
  label: 'Inspiration',
  anchor: '#inspiration'
}, {
  label: 'How it works',
  anchor: '#how-it-works'
}, {
  label: 'Tips',
  anchor: '#tips'
}, {
  label: 'FAQ',
  anchor: '#faq'
}];
var Header = function () {
  var track = useEgClickstream();
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var context = usePlatformContext().context;
  var handleAnchorClick = function (anchor) {
    var anchorText = anchor.replace('#', '');
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_redirect_to_section.selected',
      action_location: "header-nav-".concat(anchorText)
    }, {
      additional_context: {
        user_interface: {
          component_name: 'header-nav',
          component_element_text: anchorText
        }
      }
    });
  };
  return React.createElement(UitkLayoutPosition, {
    type: "sticky",
    position: {
      top: 'zero',
      zindex: 'layer1'
    }
  }, React.createElement(GlobalNavigationSiteHeader, {
    className: "Header"
  }, React.createElement(UitkSpacing, {
    padding: {
      small: {
        inline: 'six'
      }
    }
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    className: "CVHeader__logo"
  }, React.createElement(UitkLink, {
    onClickCapture: function () {
      return headerLogoSelectedEvent(track);
    }
  }, React.createElement(Link, {
    to: "/".concat(makeLocaleParam(context.locale), "/travel-video")
  }, React.createElement("img", {
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  }))))), React.createElement("div", {
    className: "show-desktop"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "three",
    className: "TVHeader__nav"
  }, navItems.map(function (link, ind) {
    return React.createElement(UitkTertiaryButton, {
      onClick: function () {
        return handleAnchorClick(link.anchor);
      },
      key: "header-nav-".concat(ind)
    }, React.createElement("a", {
      href: link.anchor
    }, link.label));
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement("nav", {
    className: "Header__nav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul",
    wrap: "wrap"
  }, React.createElement("li", null, React.createElement(SigninAndJoinNav, null)))))))));
};
export { Header };