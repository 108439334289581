// 3rd party imports
import * as React from 'react';
import { usePlatformContext } from '@bernie/platform-context';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';
// ui
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { homeJoinNowSelectedEvent, makeLocaleParam } from 'shared/utils';
var JoinNowAction = function (_a) {
  var size = _a.size,
    actionLocation = _a.actionLocation;
  var track = useEgClickstream();
  var context = usePlatformContext().context;
  return React.createElement(React.Fragment, null, React.createElement(UitkPrimaryButton, {
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signup"),
    "data-cy": "sign-up-link",
    size: size ? size : UitkButtonSize.MEDIUM,
    onClickCapture: function () {
      homeJoinNowSelectedEvent(track, actionLocation);
    }
  }, React.createElement(LocalizedText, {
    message: "common.join_now"
  })));
};
export { JoinNowAction };