var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
// 3rd party imports
import * as React from 'react';
import { useState } from 'react'; //Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkTable, UitkTableBody, UitkTableCell, UitkTableCellBorder, UitkTableCellTag, UitkTableRow } from 'uitk-react-tables';
import { UitkList } from 'uitk-react-list';
import { UitkLink } from 'uitk-react-link';
import { EventType, makeLocaleParam, useCommonClickStreamEvent } from 'shared/utils';
import { usePlatformContext } from '@bernie/platform-context';
export function Faq() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var handleAffiliateRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-faq-link'
    });
  };
  var context = usePlatformContext().context;
  var localeParam = makeLocaleParam(context.locale);
  var faqs = [{
    isVisible: true,
    expandoTitle: 'What is Expedia Group Affiliate Program? Who can apply?',
    expandoDescription: React.createElement(UitkLayoutFlex, {
      direction: "column",
      space: "three"
    }, React.createElement(UitkText, {
      size: 400
    }, "The Expedia Group Affiliate Program is designed for individuals and businesses to earn commissions by promoting Expedia travel products. It is a similar way to earn real money through your travel content as you\u2019ll be paid when someone books through one of your links.", ' '), React.createElement(UitkText, {
      size: 400
    }, "Anyone can apply, and we review all applications within minutes. If you'd like to ", React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink",
      onClickCapture: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://console.vap.expedia.com/eg-affiliates/signup"
    }, "become an affiliate")), ' ', "and earn commission from selling travel!"))
  }, {
    isVisible: false,
    expandoTitle: 'What is Travel Videos (beta)?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Travel Videos (beta) is designed to gather feedback and insights on how we can enable new monetization opportunities for Expedia affiliates. As a beta participant, you'll submit travel videos aligning with our content guidelines to earn $150 for every video approved.")
  }, {
    isVisible: false,
    expandoTitle: 'How long will the beta program last?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "The duration of the beta program is currently undetermined. We are closely monitoring program performance and participant feedback. Please note that beta features can change during this period, and you may encounter slower content reviews and performance issues. We\u2019ll be sure to provide updates as the program evolves and communicate when the official public release is planned."))
  }, {
    isVisible: false,
    expandoTitle: 'How do I submit a travel video?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "Join the Expedia Group Affiliate Program to submit a video following the", ' ', React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/travel-video/video-guidelines"
    }, "video content guidelines"), "."))
  }, {
    isVisible: false,
    expandoTitle: 'Can I submit multiple travel videos?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "Absolutely! Submit as many videos as you\u2019d like to increase your chances of getting featured and earning money."))
  }, {
    isVisible: false,
    expandoTitle: 'What video specifications do I need to follow?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "We look for content with the following specs:"), React.createElement(UitkTable, {
      border: true,
      isRounded: true,
      className: "CVFaq__Table"
    }, React.createElement(UitkTableBody, null, React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Video Duration"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "Videos should be 10-30 seconds, with a maximum of 60 seconds.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Format"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "We can only accept videos in", ' ', React.createElement(UitkText, {
      className: "CVText__italicize",
      inline: true
    }, ".mp4"), ' ', "and", ' ', React.createElement(UitkText, {
      className: "CVText__italicize",
      inline: true
    }, ".mov"), ' ', "formats.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "File Size"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "File size must not exceed 500 MB.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Resolution"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "The video resolution should be 1080 x 1920 pixels or higher.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Orientation"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "All clips must be filmed in portrait mode (9:16).")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Audio"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "Refrain from including music, voiceovers, or other sound effects. Expedia Group may remove or replace audio on accepted video submissions.")))))
  }, {
    isVisible: false,
    expandoTitle: 'How long does it take for Expedia to review videos? ',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "We review submissions as quickly as possible, but the exact timeframe varies based on submission volume, along with other factors.")
  }, {
    isVisible: false,
    expandoTitle: 'How will I know if my submission qualifies for earning? ',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "We\u2019ll send you an email after we complete our review. Be sure to check your junk mail folders so you don\u2019t miss important updates. If your video is selected, you\u2019ll receive a confirmation email and then you\u2019ll be able to see your", ' ', React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink",
      onClickCapture: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://help.affiliates.expediagroup.com/hc/en-us/articles/15185581399447-How-am-I-paid"
    }, "payment")), ".")
  }, {
    isVisible: false,
    expandoTitle: 'What rights does Expedia have to use my video?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "If your video is approved, Expedia will own a non-exclusive license to use it across our apps, sites, and social channels (Instagram, Facebook, TikTok, and more) as applicable. This means your video belongs to you and can be reused across other platforms and brand opportunities. Review our", ' ', React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink"
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=".concat(localeParam)
    }, "terms and conditions")), ' ', "for full details.")
  }, {
    isVisible: false,
    expandoTitle: 'How can I ensure my video has the best chance of being selected?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "Following our", ' ', React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/travel-video/video-guidelines"
    }, "content guidelines"), ' ', "ensures your video has the best chance of being selected. To highlight a few important elements from the guidelines:"), React.createElement(UitkList, null, React.createElement("li", {
      key: "Faq-list-best-chance-brands"
    }, React.createElement(UitkText, {
      size: 400
    }, "Don't mention or show brands or properties not available on an", ' ', React.createElement(UitkLink, {
      inline: true
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://www.expediagroup.com/travel-with-us/default.aspx"
    }, "Expedia Group brand")), ".")), React.createElement("li", {
      key: "Faq-list-best-chance-price"
    }, React.createElement(UitkText, {
      size: 400
    }, "Don't mention prices, promotional information, discount claims, or time-sensitive information.")), React.createElement("li", {
      key: "Faq-list-best-chance-cta"
    }, React.createElement(UitkText, {
      size: 400
    }, "Don't make any calls to action that cannot apply across all social media sites (e.g. \u201CLink in bio!\u201D).")), React.createElement("li", {
      key: "Faq-list-best-chance-text"
    }, React.createElement(UitkText, {
      size: 400
    }, "Make sure your videos don't include text, graphics, watermarks, logos, or emojis.")), React.createElement("li", {
      key: "Faq-list-best-chance-pov"
    }, React.createElement(UitkText, {
      size: 400
    }, "Consider 1st and 3rd POV when capturing content. Refrain from including self and other identifiable people in the video. Refrain from crowds and selfies, but anonymous people are okay, like capturing individuals or crowds from behind."))))
  }];
  var _a = __read(useState(faqs), 2),
    list = _a[0],
    setList = _a[1];
  return React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "twelve",
    className: "CVFaq"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "six"
  }, React.createElement(UitkText, {
    size: 400,
    className: "cc-text-neutral-1-500"
  }, "FAQ"), React.createElement(UitkHeading, {
    size: 4,
    align: "center"
  }, "Frequently asked questions"), React.createElement(UitkText, {
    size: 500,
    className: "cc-text-neutral-1-600",
    align: "center"
  }, "Everything you need to know")), React.createElement(UitkExpandoList, {
    className: "CVFaq__expando-list"
  }, list.map(function (listItem, index) {
    return React.createElement("div", {
      className: "CVFaq__expando-list-item",
      key: "faq-list-item-".concat(index)
    }, React.createElement(UitkExpandoListItem, __assign({}, listItem, {
      onToggle: function () {
        listItem.isVisible = !listItem.isVisible;
        trackEvent({
          event_type: EventType.INTERACTION,
          event_name: 'creator_video_faq.selected',
          action_location: 'travel-video-faq'
        }, {
          article: {
            article_name: listItem.expandoTitle
          }
        });
        setList(__spreadArray([], __read(list), false));
      },
      triggerPosition: "trailing"
    })));
  }))));
}