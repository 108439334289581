import * as React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkFigure } from 'uitk-react-images';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon } from 'uitk-react-icons';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
// Should be moved
import { HeroVideo, useCreatorVideoSetup } from 'features/creator-video';
import { Faq, Footer, Header, HowItWorks, Inspiration, Tips, TravelVideoBenefits } from 'features/travel-video-landing';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkBadgeStandard } from 'uitk-react-badge';
function LandingPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  useCreatorVideoSetup();
  return React.createElement(React.Fragment, null, React.createElement(Header, null), React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement("div", {
    className: "CVHero__section"
  }, React.createElement("div", {
    className: "CVHero__heading-flex"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six"
  }, React.createElement("div", {
    className: "CVHero__header"
  }, React.createElement(UitkBadgeStandard, {
    className: "travel-video-beta-tag"
  }, "Beta"), React.createElement(UitkHeading, {
    className: "text-uppercase"
  }, "Travel Videos"), React.createElement(UitkHeading, {
    size: 2,
    tag: "h1"
  }, "Create. Share. Earn.")), React.createElement(UitkText, {
    className: "CVHero__heading-paragraph"
  }, "Become an", ' ', React.createElement(UitkLink, {
    inline: true
  }, React.createElement("a", {
    className: "no-text-decoration",
    href: "https://affiliates.expediagroup.com"
  }, "Expedia Group affiliate")), ' ', "and share videos of the amazing properties you stayed in with us. You\u2019ll earn $150 for each video we feature on our sites, apps, and social media!")), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement("a", {
    href: "https://console.vap.expedia.com/eg-affiliates"
  }, React.createElement(UitkPrimaryButton, {
    className: "CVHero__heading-button",
    size: UitkButtonSize.LARGE,
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'travel-video-landing-page-hero-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'hero button'
          }
        }
      });
    }
  }, "Join now", React.createElement(UitkIcon, {
    name: "open_in_new"
  })))))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement("div", {
    className: "CVHero__relative-image"
  }, React.createElement(UitkFigure, {
    className: "CVHero__media-image"
  }, React.createElement(HeroVideo, {
    url: "https://mediaim.expedia.com/creator-video/creator-hero.mp4",
    poster: "/images/travel-video-landing/poster-hero.png"
  })), React.createElement("img", {
    className: "CVHero__svg CVHero__star-svg",
    src: "/images/creator-video/landing-page-star.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__record-svg",
    src: "/images/creator-video/landing-page-record.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__flash-scribble-svg",
    src: "/images/creator-video/flash-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__dark-blue-svg",
    src: "/images/creator-video/dark-blue-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__button-svg",
    src: "/images/creator-video/creator-video-button.svg"
  })))))), React.createElement("section", {
    className: "CVSection",
    id: "benefits"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, "Benefits"), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, "What's in it for you?")), React.createElement(TravelVideoBenefits, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    href: "https://console.vap.expedia.com/eg-affiliates"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'travel-video-landing-page-benefits-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'benefits button'
          }
        }
      });
    },
    size: UitkButtonSize.LARGE
  }, "Join now", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Inspiration, null), React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'twelve'
    }
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    href: "https://console.vap.expedia.com/eg-affiliates"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'travel-video-landing-page-Inspiration-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'inspiration button'
          }
        }
      });
    },
    size: UitkButtonSize.LARGE
  }, "Submit videos", React.createElement(UitkIcon, {
    name: "open_in_new"
  })))))), React.createElement("section", {
    className: "CVSection",
    id: "how-it-works"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, "How does it work?"), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, "3 easy steps")), React.createElement(HowItWorks, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    href: "https://console.vap.expedia.com/eg-affiliates"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'travel-video-landing-page-how-it-works-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'how does it work button'
          }
        }
      });
    },
    size: UitkButtonSize.LARGE
  }, "Join now", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection",
    id: "tips"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, "Tips"), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, "Travel videos best practices")), React.createElement(Tips, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    href: "https://console.vap.expedia.com/eg-affiliates"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'travel-video-landing-page-tips-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'tips button'
          }
        }
      });
    },
    size: UitkButtonSize.LARGE
  }, "Submit videos", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection",
    id: "faq"
  }, React.createElement(Faq, null)), React.createElement(Footer, null));
}
export default LandingPage;
export { LandingPage };