var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
// Should move
import { Carousel, InspirationDisplayVideo } from 'features/creator-video';
export function Inspiration() {
  var _a = __read(React.useState([{
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/wenCx-travel-video-1.mp4',
      poster: '/images/travel-video-landing/travel-video-poster-1.png'
    }, {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/ldIuy-travel-video-2.mp4',
      poster: '/images/travel-video-landing/travel-video-poster-2.png'
    }, {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/PDoaz-travel-video-3.mp4',
      poster: '/images/travel-video-landing/travel-video-poster-3.png'
    }, {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/UHk8L-travel-video-4.mp4',
      poster: '/images/travel-video-landing/travel-video-poster-4.png'
    }]), 1),
    inspirationVideos = _a[0];
  return React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    className: "CVSection__padding CVGap-64 CVSection__width",
    id: "inspiration"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    className: "CVGap-16"
  }, React.createElement(UitkText, {
    size: 400,
    weight: "medium",
    align: "center"
  }, "Inspiration"), React.createElement(UitkHeading, {
    size: 2,
    tag: "h2",
    align: "center"
  }, "Videos to get you inspired")), React.createElement("div", {
    className: "CVInspiration__example-carousel"
  }, React.createElement(Carousel, {
    className: "inspiration"
  }, inspirationVideos.map(function (obj, ind) {
    return React.createElement("div", {
      className: "CVCarousel__flex-item",
      key: "cv-inspiration__carousel-item-".concat(ind)
    }, React.createElement(InspirationDisplayVideo, __assign({}, obj)));
  }))), React.createElement("div", {
    className: "CVInspiration__example-flex"
  }, inspirationVideos.map(function (obj, ind) {
    return React.createElement(InspirationDisplayVideo, __assign({
      key: "cv-inspiration__flex-item-".concat(ind)
    }, obj));
  }))));
}