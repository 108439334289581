var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
// 3rd party imports
import * as React from 'react';
import { useState } from 'react';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';
// ui
import { UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import { headerSigninSelectedEvent, makeLocaleParam } from 'shared/utils';
import { JoinNowAction } from '../join-now-action';
import { usePlatformContext } from '@bernie/platform-context';
var SigninAndJoinNav = function () {
  var track = useEgClickstream();
  var context = usePlatformContext().context;
  var _a = __read(useState(false), 2),
    menuOpen = _a[0],
    setMenuOpen = _a[1];
  var handleMenuClick = function () {
    setMenuOpen(!menuOpen);
  };
  var signInText = React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "common.sign_in"
  }));
  var signInUrl = "/".concat(makeLocaleParam(context.locale), "/signin");
  var signInLink = React.createElement("a", {
    href: signInUrl,
    "data-cy": "header-sign-in-link"
  }, signInText);
  return React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul"
  }, React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(UitkTertiaryButton, {
    onClickCapture: function () {
      return headerSigninSelectedEvent(track);
    }
  }, signInLink)), React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(JoinNowAction, {
    actionLocation: "header"
  })), React.createElement("li", {
    className: "visible-mobile--large"
  }, React.createElement(UitkMenu, {
    isOpen: menuOpen,
    onTriggerClick: handleMenuClick
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkTertiaryButton, {
    "data-stid": "mobile-nav-menu-btn"
  }, React.createElement(UitkIcon, {
    name: "account_circle",
    className: "Header__icon"
  }))), React.createElement(UitkMenuContainer, {
    autoPosition: true,
    className: "HeaderJoinNow__menu_item"
  }, React.createElement(UitkSpacing, {
    padding: {
      inline: 'four',
      blockstart: 'half',
      blockend: 'two'
    }
  }, React.createElement(UitkList, {
    bullet: "no-bullet",
    "data-stid": "mobile-nav-menu"
  }, React.createElement(UitkListItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(JoinNowAction, {
    actionLocation: "header"
  })))), React.createElement(UitkListItem, null, React.createElement(UitkSpacing, {
    padding: {
      inline: 'twelve'
    }
  }, React.createElement(UitkSecondaryButton, {
    tag: "a",
    href: signInUrl,
    isFullWidth: true,
    "data-cy": "header-sign-in-link",
    onClickCapture: function () {
      return headerSigninSelectedEvent(track);
    }
  }, signInText)))))))));
};
export { SigninAndJoinNav };