var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { useState } from 'react';
import { UitkIllustrations } from 'uitk-react-illustrations';
import { unsubscribeSelectedEvent } from 'shared/utils';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
var UnsubscribeRequestComponent = observer(
// tslint:disable-next-line:no-shadowed-variable
function UnsubscribeFormComponent(props) {
  var _this = this;
  var email = props.email,
    unsubscribeAction = props.unsubscribeAction;
  var track = useEgClickstream();
  var _a = __read(useState(false), 2),
    inProgress = _a[0],
    setInProgress = _a[1];
  var handleUnsubscribe = function () {
    return __awaiter(_this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        setInProgress(true);
        unsubscribeSelectedEvent(track);
        unsubscribeAction();
        return [2 /*return*/];
      });
    });
  };
  return React.createElement(UitkSpacing, {
    className: "container--small-1",
    padding: {
      block: 'twelve'
    }
  }, React.createElement("main", {
    className: "Unsubscribe__container"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "start",
    justifyContent: "space-between",
    wrap: "wrap"
  }, React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkIllustrations, {
    alt: "Mail",
    url: "/images/unsubscribe/mail.svg",
    className: "unsubscribeIcon"
  }))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkHeading, {
    tag: "h1",
    size: 4
  }, "Need to unsubscribe?"))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkText, null, "Unsubscribe from all marketing emails from the Expedia Group Affiliate Program. You will still receive service emails and other important information."))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkText, null, "Please confirm your email below."))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      block: 'four',
      inline: 'two'
    }
  }, React.createElement("div", null, React.createElement(UitkText, {
    size: 300
  }, "Email:"), React.createElement(UitkText, null, email)))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkPrimaryButton, {
    type: "button",
    size: UitkButtonSize.LARGE,
    onClick: handleUnsubscribe,
    disabled: inProgress
  }, "Unsubscribe"))))));
});
export var UnsubscribeRequest = withRouter(UnsubscribeRequestComponent);