var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useLocalization } from '@shared-ui/localization-context';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'shared/hooks';
import { LayoutLite } from 'shared/layout';
import { UnsubscribeComplete, UnsubscribeRequest } from 'features/unsubscribe';
import { unsubscribePresentedEvent, unsubscribeFailedEvent, unsubscribeSucceedEvent } from 'shared/utils';
import { validate as isUUID } from 'uuid';
import { useLogger } from 'shared/hooks';
var UnsubscribePage = observer(
// tslint:disable-next-line:no-shadowed-variable
function UnsubscribePage() {
  var _this = this;
  var unsubscribeStore = useStores().unsubscribeStore;
  var l10n = useLocalization();
  var useEffect = React.useEffect;
  var history = useHistory();
  var track = useEgClickstream();
  var logger = useLogger();
  useEffect(function () {
    document.title = l10n.formatText('unsubscribe.page.title');
    unsubscribePresentedEvent(track);
  }, []);
  var _a = __read(useState(''), 2),
    email = _a[0],
    setEmail = _a[1];
  var _b = __read(useState(''), 2),
    partnerId = _b[0],
    setPartnerId = _b[1];
  var queryParams = new URLSearchParams(history.location.search);
  useEffect(function () {
    var _a;
    // TODO remove support for the email parameter
    var emailParam = (_a = queryParams.get('email')) === null || _a === void 0 ? void 0 : _a.replace(' ', '+').trim();
    var partnerParam = queryParams.get('id');
    if (!emailParam && !partnerParam) {
      history.push('/');
      return;
    }
    if (isUUID(emailParam)) {
      setPartnerId(emailParam);
      return;
    }
    if (partnerParam) {
      if (isUUID(partnerParam)) {
        setPartnerId(partnerParam);
      } else {
        history.push('/');
      }
      return;
    }
    setEmail(emailParam);
  }, []);
  useEffect(function () {
    if (partnerId) {
      if (!isUUID(partnerId)) {
        history.push('/');
      }
      // Using then/catch solves the problem of trying to use an await here
      unsubscribeStore.getPartnerForId(partnerId).then(function (response) {
        if (response.body.length === 0) {
          history.push('/');
        } else {
          setEmail(response.body[0]);
        }
      }).catch(function () {
        history.push('/');
      });
    }
  }, [partnerId]);
  var _c = __read(useState(false), 2),
    unsubscribed = _c[0],
    setUnsubscribed = _c[1];
  var unsubscribeAction = function () {
    return __awaiter(_this, void 0, void 0, function () {
      var error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 5,, 6]);
            if (!partnerId) return [3 /*break*/, 2];
            return [4 /*yield*/, unsubscribeStore.setUnsubscribedByPartner(partnerId)];
          case 1:
            _a.sent();
            logger.info("Unsubscribed, partnerId=".concat(partnerId));
            return [3 /*break*/, 4];
          case 2:
            return [4 /*yield*/, unsubscribeStore.setUnsubscribed(email)];
          case 3:
            _a.sent();
            logger.info("Unsubscribed, email=".concat(email));
            _a.label = 4;
          case 4:
            unsubscribeSucceedEvent(track);
            return [3 /*break*/, 6];
          case 5:
            error_1 = _a.sent();
            unsubscribeFailedEvent(track);
            logger.warn("Could not unsubscribe, partnerId=".concat(partnerId, ", email=").concat(email));
            return [3 /*break*/, 6];
          case 6:
            setUnsubscribed(true);
            return [2 /*return*/];
        }
      });
    });
  };
  return React.createElement(LayoutLite, {
    centered: true
  }, unsubscribed ? React.createElement(UnsubscribeComplete, null) : React.createElement(UnsubscribeRequest, {
    partnerId: partnerId,
    email: email,
    unsubscribeAction: unsubscribeAction
  }));
});
export default UnsubscribePage;
export { UnsubscribePage };