import React, { useId } from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
export function TravelVideoBenefits() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var handleAffiliateRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-benefits-link'
    });
  };
  var benefits = [{
    icon: '/images/travel-video-landing/featured.svg',
    title: 'Earn for every featured video',
    content: React.createElement(UitkText, {
      size: 400,
      align: "center"
    }, "Each video we use earns you $150. There's no limit on submissions, so keep uploading for a chance to fund your next trip!")
  }, {
    icon: '/images/travel-video-landing/goggles.svg',
    title: 'See your content come to life',
    content: React.createElement(UitkText, {
      size: 400,
      align: "center"
    }, "We'll share your content across our sites and social pages, where millions of travelers will be inspired by what you've created")
  }, {
    icon: '/images/travel-video-landing/chart.svg',
    title: 'Unlock more earning opportunities',
    content: React.createElement(UitkText, {
      size: 400,
      align: "center"
    }, "Leverage creator tools within our", ' ', React.createElement(UitkLink, {
      inline: true,
      onClick: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      href: "https://console.vap.expedia.com/eg-affiliates/signup"
    }, "affiliate program")), ' ', "to earn even more with your content. Not an affiliate yet? Join the program today!")
  }];
  return React.createElement("div", null, React.createElement(UitkLayoutGrid, {
    columns: {
      small: 1,
      large: 3
    },
    space: "twelve"
  }, benefits.map(function (item) {
    return React.createElement(UitkSpacing, {
      padding: {
        inline: 'six'
      },
      key: useId()
    }, React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "center",
      justifyContent: "start",
      space: "six",
      key: useId()
    }, React.createElement("img", {
      className: 'cc-three-tile__small-grid-svg',
      src: item.icon
    }), React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "center",
      justifyContent: "center",
      space: "three"
    }, React.createElement(UitkHeading, {
      size: 5,
      align: "center"
    }, item.title), item.content)));
  })));
}