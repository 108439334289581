import React from 'react';
import { Link } from 'react-router-dom';
import { UitkTertiaryButton } from 'uitk-react-button';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkSpacing } from 'uitk-react-spacing';
import { HeaderActions, navItems } from 'features/travel-creator-landing';
import { usePlatformContext } from '@bernie/platform-context';
import { makeLocaleParam } from 'shared/utils';
var Header = function () {
  var context = usePlatformContext().context;
  return React.createElement(UitkLayoutPosition, {
    type: "sticky",
    position: {
      top: 'zero',
      zindex: 'layer1'
    }
  }, React.createElement(GlobalNavigationSiteHeader, {
    className: "TCHeader"
  }, React.createElement(UitkSpacing, {
    padding: {
      small: {
        inline: 'four'
      },
      medium: {
        inline: 'six'
      }
    }
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(Link, {
    to: "/".concat(makeLocaleParam(context.locale), "/travel-creator")
  }, React.createElement("img", {
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program",
    className: "TCHeader__logo"
  }))), React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "three",
    className: "TCHeader__nav show-desktop"
  }, navItems.map(function (link, ind) {
    return React.createElement(UitkTertiaryButton, {
      key: "header-nav-".concat(ind)
    }, React.createElement("a", {
      href: link.anchor
    }, link.label));
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(HeaderActions, null))))));
};
export { Header };