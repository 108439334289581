export var navItems = [{
  label: 'What\'s in it for you?',
  anchor: '#whats-in-it-for-you'
}, {
  label: 'How it works',
  anchor: '#how-it-works'
}, {
  label: 'Testimonials',
  anchor: '#testimonials'
}, {
  label: 'FAQs',
  anchor: '#faq'
}];