var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React, { useId } from 'react';
import { UitkText } from 'uitk-react-text';
import { UitkCarousel } from 'uitk-react-carousel';
import { UitkHeading } from 'uitk-react-text';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
export function Tips() {
  var benefits = [{
    icon: '/images/travel-video-landing/devices.svg',
    title: 'Think ‘mobile-friendly’',
    content: React.createElement(UitkText, {
      size: 400
    }, "Videos filmed in a portrait orientation (9:16) are perfect for TikTok, Instagram Reels, and YouTube Shorts. Additionally, keep in mind short attention spans. Hook viewers within the first 5 seconds.")
  }, {
    icon: '/images/travel-video-landing/snip.svg',
    title: 'Keep it short and sweet',
    content: React.createElement(UitkText, {
      size: 400
    }, "We've seen that 30 seconds is the sweet spot for our best-performing content. If you have lots of content on a property, make a separate video!")
  }, {
    icon: '/images/travel-video-landing/privacy.svg',
    title: 'Be mindful of privacy',
    content: React.createElement(UitkText, {
      size: 400
    }, "Avoid including identifiable people and minors to protect privacy and ensure your video meets our content guidelines.")
  }, {
    icon: '/images/travel-video-landing/details.svg',
    title: 'It’s all in the details',
    content: React.createElement(UitkText, {
      size: 400
    }, "Show off the property by spotlighting 2-3 key areas in one video. Capture the exterior, stunning views, and inviting interiors, plus amenities like pools, dining, and the special touches that stand out.")
  }, {
    icon: '/images/travel-video-landing/keys.svg',
    title: 'Authenticity is key',
    content: React.createElement(UitkText, {
      size: 400
    }, "Today, viewers are savvy as to if you\u2019re speaking genuinely. Ensure your content is your own and don\u2019t be afraid to show your personality.")
  }, {
    icon: '/images/travel-video-landing/less.svg',
    title: 'Less is more',
    content: React.createElement(UitkText, {
      size: 400
    }, "Keep the your video presentation clean by skipping fonts, stickers, and emojis so your content can shine without unnecessary distractions.")
  }];
  var props = {
    carouselName: 'Carouseledgetoedge',
    carouselHeaderText: 'Carousel with edge-to-edge layout via inner spacing',
    useContainerSizing: false,
    itemsMaxHeight: true,
    innerSpacing: {
      sm: 48,
      md: 32,
      lg: 24
    },
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3
    },
    peek: true,
    peekAmount: {
      sm: 64,
      md: 48,
      lg: 24
    },
    itemsGap: {
      sm: 24,
      md: 12,
      lg: 12
    },
    pagingInset: true,
    wrapItems: true,
    navigationIconId: {
      next: 'next-button',
      prev: 'prev-button'
    },
    buttonText: {
      nextButton: 'Show next card',
      prevButton: 'Show previous card'
    }
  };
  return React.createElement("div", null, React.createElement(UitkCarousel, __assign({}, props, {
    className: "hide-desktop"
  }), benefits.map(function (item) {
    return React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "start",
      space: "six",
      key: useId()
    }, React.createElement("img", {
      src: item.icon
    }), React.createElement(UitkLayoutFlex, {
      direction: "column",
      space: "two"
    }, React.createElement(UitkHeading, {
      size: 5
    }, item.title), React.createElement("div", null, item.content)));
  })), React.createElement(UitkLayoutGrid, {
    columns: 3,
    space: "twelve",
    className: "show-desktop"
  }, benefits.map(function (item) {
    return React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "start",
      space: "six",
      key: useId()
    }, React.createElement("img", {
      src: item.icon
    }), React.createElement(UitkLayoutFlex, {
      direction: "column",
      space: "three"
    }, React.createElement(UitkHeading, {
      size: 5
    }, item.title), React.createElement("div", null, item.content)));
  })));
}