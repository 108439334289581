var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from 'react';
import { useViewportMap, ViewSizes } from '@shared-ui/viewport-context';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { usePlatformContext } from '@bernie/platform-context';
import { makeLocaleParam } from 'shared/utils';
import { UitkSpacing } from 'uitk-react-spacing';
var HeroSection = function () {
  var _a, _b, _c, _d;
  var context = usePlatformContext().context;
  var contentContainerViewportProps = useViewportMap((_a = {}, _a[ViewSizes.SMALL] = {
    direction: 'column-reverse'
  }, _a[ViewSizes.MEDIUM] = {
    direction: 'row'
  }, _a));
  var headingViewportProps = useViewportMap((_b = {}, _b[ViewSizes.SMALL] = {
    align: 'center',
    size: 4
  }, _b[ViewSizes.MEDIUM] = {
    align: 'left',
    size: 4
  }, _b[ViewSizes.LARGE] = {
    size: 3
  }, _b));
  var subheadingViewportProps = useViewportMap((_c = {}, _c[ViewSizes.SMALL] = {
    align: 'center',
    size: 300
  }, _c[ViewSizes.MEDIUM] = {
    align: 'left',
    size: 400
  }, _c[ViewSizes.LARGE] = {
    size: 500
  }, _c));
  var buttonContainerViewportProps = useViewportMap((_d = {}, _d[ViewSizes.SMALL] = {
    justifyContent: 'center'
  }, _d[ViewSizes.MEDIUM] = {
    alignItems: 'start'
  }, _d));
  return React.createElement("section", {
    className: "TCHero"
  }, React.createElement(UitkLayoutFlex, __assign({
    space: 'six',
    className: "container"
  }, contentContainerViewportProps), React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: 'four',
    justifyContent: 'center'
  }, React.createElement(UitkLayoutFlexItem, {
    className: "TCHero__heading__container"
  }, React.createElement(UitkHeading, __assign({
    tag: "h1"
  }, headingViewportProps), "Discover new ways to earn from your travel content")), React.createElement(UitkLayoutFlexItem, {
    className: "TCHero__subheading__container"
  }, React.createElement(UitkText, __assign({
    theme: "inverse",
    spacing: 'one'
  }, subheadingViewportProps), "Curate and share content effortlessly, get featured, and access top travel brands like Expedia, Hotels.com, and Vrbo to expand your reach.")), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, __assign({}, buttonContainerViewportProps), React.createElement(UitkSpacing, {
    padding: {
      blockstart: "six"
    }
  }, React.createElement("div", null, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.LARGE,
    href: "/".concat(makeLocaleParam(context.locale), "/signup"),
    inverse: true,
    className: 'TCHero__button'
  }, "Begin your journey now"))))))), React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    className: "TCHero__video"
  }, React.createElement("img", {
    src: "/images/travel-creator-landing/hero-image.png",
    alt: "placeholder content"
  })))))));
};
export { HeroSection };