import { NOOP_LOGGER } from 'bernie-logger';
import { UiStore } from 'shared/stores';
import { UnsubscribeStore } from 'features/unsubscribe';
export var stores = {
  uiStore: function () {
    return new UiStore({}, {}, NOOP_LOGGER);
  },
  unsubscribeStore: function () {
    return new UnsubscribeStore({}, {}, NOOP_LOGGER);
  }
};