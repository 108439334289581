import * as React from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkIllustrations } from 'uitk-react-illustrations';
var UnsubscribeCompleteComponent = observer(
// tslint:disable-next-line:no-shadowed-variable
function UnsubscribeCompleteComponent() {
  // TODO - localise text
  return React.createElement(UitkSpacing, {
    className: "container--small-1",
    padding: {
      block: 'twelve'
    }
  }, React.createElement("main", {
    className: "Unsubscribe__container"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "start",
    justifyContent: "space-between",
    wrap: "wrap"
  }, React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkIllustrations, {
    alt: "Complete",
    url: "/images/unsubscribe/deck_chair.svg",
    className: "unsubscribeIcon"
  }))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkHeading, {
    tag: "h1",
    size: 4
  }, "You have been unsubscribed"))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement(UitkText, null, "We're sorry to see you go. Please keep in mind that it may take up to two days for your request to be fully processed. Don't forget that you'll still receive important service emails."))))));
});
export var UnsubscribeComplete = withRouter(UnsubscribeCompleteComponent);